<template>
  <div class="container-home">
    <img
      src="../assets/logo header.svg"
      alt="Logo Gerdau"
      style="width: 900px"
    />
    <p>{{ $t('application.smartBioLogistic') }}</p>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.container-home {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.container-home p {
  font-weight: bold;
  font-size: 4rem;
  color: #959db5;
  text-transform: uppercase;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

</style>
