<template>
  <bc-app>
    <bc-app-bar app dense flat color="primary-gray">
      <div id="header" class="d-flex align-center">
        <bc-drawer :items="filteredMenuItems" />
        <div id="header-container">
          <div class="header-container">
            <a href="/" class="logo-anchor">
              <img src="../assets/logo header.svg" alt="Logo Gerdau" />
            </a>
            <bc-divider vertical class="vertical"></bc-divider>
            <span class="page-title">{{
              $t('application.companyModule')
            }}</span>
          </div>
          <div>
            <bc-popover-icon
              icon="fa-user"
              :tooltip="$t('application.user.userProperties')"
              :dismissible="true"
              class="margin-right-14px"
            >
              <template v-slot:header>{{
                $t('application.user.userProperties')
              }}</template>
              <user-action />
            </bc-popover-icon>
          </div>
        </div>
      </div>

      <bc-spacer></bc-spacer>
    </bc-app-bar>

    <bc-main>
      <router-view />
    </bc-main>

    <bc-footer color="primary-gray"> </bc-footer>
  </bc-app>
</template>

<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import UserAction from '../components/controls-bar-menu/user-action'
import {
  BcImg,
  BcSpacer,
  BcApp,
  BcAppBar,
  BcMain,
  BcBreadcrumbsRouter,
  BcDrawer,
  BcFooter,
  BcBoxSelector,
  BcPopoverIcon,
  BcDivider
} from '@brain/core'

import menuItems from '../router/menu'
import { useTransactions } from '../stores/transaction'
import Home from '../views/Home.vue'

export default defineComponent({
  name: 'Main',
  components: {
    BcImg,
    BcSpacer,
    BcApp,
    BcAppBar,
    BcMain,
    BcDrawer,
    BcFooter,
    BcBreadcrumbsRouter,
    BcBoxSelector,
    BcPopoverIcon,
    BcDivider,
    UserAction,
    Home
  },
  setup(props, ctx) {
    const { canRead } = useTransactions()
    let boxSelectorData = [
      {
        icon: 'fas fa-mountain',
        name: 'Matéria Prima',
        disabled: false,
        href: '/'
      },
      {
        icon: 'far fa-fireplace',
        name: 'Recuperação',
        disabled: false,
        href: '/recuperation'
      },
      {
        icon: 'fas fa-bars',
        name: 'Parâmetros',
        disabled: false,
        href: '/smartbio-parameters',
        transaction: 'SmartbioParameter'
      }
    ].filter(({ transaction }) => {
      if (transaction && canRead(transaction)) return true
      if (!transaction) return true
      return false
    })
    const currentBoxSelector = ref()
    const onSelectModel = (newBoxSelector) => {
      if (ctx.root.$route.path != newBoxSelector.href) {
        ctx.root.$router.push(newBoxSelector.href)
      }
      currentBoxSelector.value = newBoxSelector
    }

    const filteredMenuItems = computed(() =>
      menuItems.reduce((result, item) => {
        if (item.transaction && !canRead(item.transaction)) {
          return result
        }

        const name = ctx.root.$t(item.name)
        let children = item.children
        if (children) {
          children = item.children
            .filter(
              (subItem) =>
                !(subItem.transaction && !canRead(subItem.transaction))
            )
            .map((subItem) => ({ ...subItem, name: ctx.root.$t(subItem.name) }))
        }

        return [...result, { ...item, name, children }]
      }, [])
    )

    return { filteredMenuItems, boxSelectorData, onSelectModel }
  }
})
</script>

<style lang="scss">
#header {
  width: 100%;
  #header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    width: 100%;
  }
  .app-logo {
    margin-left: 20px;
    z-index: 1001;
  }
}
::-webkit-scrollbar {
  width: 4px;
  height: 7px !important;
}

::-webkit-scrollbar-track {
  background: var(--bc-scrollbar-default-track);

  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;

  background: var(--bc-scrollbar-default-thumb-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--bc-dark-blue);
}
.header-container {
  align-items: center;
  display: flex;
}

.vertical {
  margin-left: 20px;
  height: 30px;
}
.page-title {
  font-weight: bold;
  color: #959db5;
  margin-left: 10px;
  text-transform: uppercase;
}
.margin-right-14px {
  margin-right: 14px;
}
</style>
