export default [
  {
    name: 'application.pages.home.title',
    icon: 'list-alt',
    transaction: '',
    href: '/'
  },
  {
    name: 'application.pages.vehicleOffer.title',
    icon: 'list-alt',
    transaction: '',
    href: '/vehicle-offer'
  },
  {
    name: 'application.pages.shipment.myShipments',
    icon: 'list-alt',
    transaction: '',
    href: '/shipment'
  },
  {
    name: 'application.pages.general.title',
    icon: 'list-alt',
    transaction: '',
    children: [
      {
        name: 'application.pages.vehicle.title',
        icon: 'list-alt',
        transaction: '',
        href: '/vehicle'
      },
      {
        name: 'application.pages.driver.title',
        icon: 'list-alt',
        transaction: '',
        href: '/driver'
      },
      {
        name: 'application.pages.truckTractor.title',
        icon: 'list-alt',
        transaction: '',
        href: '/truck-tractor'
      }
    ]
  },
  {
    name: 'application.pages.report.title',
    icon: 'list-alt',
    transaction: '',
    children: [
      {
        name: 'application.pages.shipment.title',
        icon: 'list-alt',
        transaction: '',
        href: '/shipment-Report'
      }
    ]
  }
]
