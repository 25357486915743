export default {
  locales: [
    {
      id: 'pt-BR',
      label: 'Português (Brazil)'
    },
    {
      id: 'en',
      label: 'English'
    }
  ]
}
