<template>
  <div class="content">
    <div class="row">
      <div class="item col-10">
        <p class="name">
          {{ $t('application.user.realName') }}: {{ userRealName }}
        </p>
        <p class="userProfile">
          {{ $t('application.user.userProfile') }}: {{ userProfile }}
        </p>
        <p class="login">
          {{ $t('application.user.userEmail') }}: {{ userEmail }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="input-language">
        <bc-select
          class="offset"
          :label="$t('application.user.language')"
          :items="languageInput.options"
          item-text="label"
          item-value="id"
          v-model="selectedLanguage"
          :value="selectedLanguage"
          @input="changeLanguage"
          filled
        ></bc-select>
      </div>
    </div>
    <div class="row logout-row">
      <span @click="logout()" class="btn btn-logout">
        {{ $t('application.user.logout') }}
      </span>
    </div>
  </div>
</template>

<script>
import Languages from '@/locales/languages.js'
import authService from '@/boot/authService'

export default {
  data() {
    return {
      languageInput: {
        name: 'changeLanguage',
        type: 'select',
        label: null,
        placeholder: null,
        options: []
      },
      selectedLanguage: {
        id: 'pt-BR',
        label: 'Português (Brazil)'
      },
      userEmail: '',
      userRealName: '',
      userProfile: '',
      smartBioAdmin: 'SmartBio - Administrador',
      smartBioFeedstock: 'SmartBio - Matéria Prima',
      smartBioBlastFurnace: 'SmartBio - Alto Forno'
    }
  },
  mounted() {
    this.getUserdata()
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    }
  },
  created() {
    this.languageInput.options = Languages.locales
    var language = JSON.parse(localStorage.getItem('language'))
    if (language) {
      this.selectedLanguage = language
      this.$i18n.locale = language.id
    } else {
      const defaultLanguage = {
        id: 'pt-BR',
        label: 'Português (Brazil)'
      }
      this.setSelectedLanguage(defaultLanguage.id)
      this.changeLanguage(defaultLanguage)
    }
  },
  methods: {
    async getUserdata() {
      let tokenParsed = authService.kc.tokenParsed
      this.userEmail = tokenParsed?.email
      this.userRealName = await authService.getUserRealName()
      this.userProfile = this.getUserProfile(tokenParsed)
    },
    getUserProfile(tokenParsed) {
      let userProfile = ''
      tokenParsed?.realm_access?.roles?.forEach((element) => {
        if (element == this.smartBioAdmin) {
          userProfile = userProfile
            ? userProfile + ', ' + this.smartBioAdmin
            : userProfile + ' ' + this.smartBioAdmin
        }
        if (element == this.smartBioFeedstock) {
          userProfile = userProfile
            ? userProfile + ', ' + this.smartBioFeedstock
            : userProfile + ' ' + this.smartBioFeedstock
        }
        if (element == this.smartBioBlastFurnace) {
          userProfile = userProfile
            ? userProfile + ', ' + this.smartBioBlastFurnace
            : userProfile + ' ' + this.smartBioBlastFurnace
        }
      })
      return userProfile
    },
    getImageSrc(avatar) {
      if (avatar == null) return ''
      const mime = avatar.mime
      const data = avatar.data
      return `data:${mime};base64,${data}`
    },
    setSelectedLanguage(lang) {
      this.selectedLanguage = this.languageInput.options.find(
        (o) => o.id === lang
      )
      localStorage.setItem('language', this.selectedLanguage)
    },
    changeLanguage(e) {
      this.$i18n.locale = e.id
      localStorage.setItem('language', JSON.stringify(e))
    },
    logout() {
      authService.doLogout()
    }
  },
  watch: {
    '$root.$i18n.locale': function () {
      this.languageInput.label = this.$t('application.user.changeLanguage')
      this.languageInput.placeholder = this.$t(
        'application.user.changeLanguage'
      )
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 450px;

  .row {
    padding: 5px;
  }

  .row:not(:last-child) {
    border-bottom: solid 2px var(--bc-black);
  }

  .input-language {
    width: 100%;
  }

  .profile {
    background-color: #f5f5f5;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-size: cover;
    cursor: pointer;
  }
  .name {
    text-transform: capitalize;
    color: #959db5;
    font-size: 18px;
    font-weight: bold;
  }
  .userProfile {
    text-transform: capitalize;
    color: #959db5;
    font-size: 16px;
    font-weight: bold;
  }
  .role {
    text-transform: none;
    font-weight: normal;
  }
  .login,
  .email {
    text-transform: lowercase;
    font-weight: normal;
    color: #959db5;
    font-size: 16px;
  }

  .logout-row {
    justify-content: flex-end;
  }

  .btn-logout {
    color: var(--bc-light);
    font-size: 18px;
    margin-top: 2px;
  }

  .btn {
    font-weight: bold;
    cursor: pointer;
  }
  .btn:hover {
    color: var(--bc-title-color);
  }
}
</style>
